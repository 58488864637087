import GroupService from '../services/group.service';

const initialState = {
  groups: [],
  group: null,
  registered: false,
  groupUsers: []
}
export const groups = {
  namespaced: true,
  state: initialState,
  actions: {
    async getGroups({ commit }) {
      let allGroups = await GroupService.getGroups();
      commit('populateGroups', allGroups.data.groups);
    },
    async addNewGroup({ commit }, group) {
      await GroupService.addGroup(group)
      commit('registerGroup')
    },
    async addNewSubscriberGroup({ commit }, group) {
      await GroupService.addGroupFromSubscribedUser(group)
      commit('registerGroup')
    },
    async getGroup({ commit }, uri) {
      const response = await GroupService.getGroup(uri)
      commit('populateGroup', response.data)
    },
    async getUserSubscriptionGroups({ commit }, uri) {
      let allGroups = await GroupService.getUserSubscriptionGroups(uri);
      commit('populateGroups', allGroups.data.groups);
    },
    async addGroupFromSubscribedUser({ commit }, group) {
      await GroupService.addGroupFromSubscribedUser(group)
      commit('registerGroup')
    },
    async getGroupUsers({ commit }, uri) {
      try {
        const response = await GroupService.getGroupUsers(uri)
        if (response.data) {
          commit('popuplateGroupUsers', response.data.users)
        }
        else {
          commit('popuplateGroupUsers', [])
        }
      }
      catch (error) {
        console.log('error', error)
      }
    },
    async addUser({ commit }, userDetails) {
      try {
        const response = await GroupService.addUserToGroup(userDetails)
        commit('registerSuccess');
        return response.data
      }
      catch (error) {
        commit('registerFailure');
        return error;
      }
    },
  },
  mutations: {
    registerGroup(state) {
      state.registered = true;
    },
    populateGroups(state, groups) {
      state.groups = groups
    },
    populateGroup(state, group) {
      state.group = group
    },
    popuplateGroupUsers(state, groupUsers) {
      state.groupUsers = groupUsers
    },
    registerSuccess(state) {
      state.registered = true
    },
    registerFailure(state) {
      state.registered = false
    }
  },
}