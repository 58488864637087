import DocumentService from '../services/document.service';

// Helper function to determine logo source based on file type
const getLogoSourceForFileType = (filetype) => {
    // Handle cases like undefined, null, or empty strings
    if (!filetype) return "document-96.png";

    // Convert to lowercase and remove any leading dot
    const type = filetype.toLowerCase().replace(/^\./, '');

    // Document types
    const documentTypes = {
        // PDF files
        "pdf": "pdf-96.png",

        // Spreadsheets
        "csv": "csv-96.png",
        "xlsx": "exel_96.png",
        "xls": "exel_96.png",

        // Images
        "jpg": "jpeg-64.png",
        "jpeg": "jpeg-64.png",
        "png": "png-96.png",
        "gif": "png-96.png",
        "svg": "png-96.png",

        // Word documents
        "doc": "word-96.png",
        "docx": "word-96.png",
        "rtf": "word-96.png",
        "txt": "word-96.png",

        // Presentations
        "ppt": "presentation-96.png",
        "pptx": "presentation-96.png",

        // Archives
        "zip": "archive-96.png",
        "rar": "archive-96.png",
        "7z": "archive-96.png",
        "tar": "archive-96.png",
        "gz": "archive-96.png"
    };

    // Return the icon for the file type or default to word document icon
    return documentTypes[type] || "document-96.png";
};

const initialState = {
    document: null,
    documents: [],
    totalItems: 0,
    downLoading: false,
    upLoading: false,
    document_name: null,
    documentText: null
}
export const documents = {
    namespaced: true,
    state: initialState,
    actions: {
        async getDocuments({ commit }) {
            let allDocuments = await DocumentService.getAllDocuments();
            allDocuments.data.documents.forEach(document => {
                document.logo_source = getLogoSourceForFileType(document.filetype);
            })
            commit('populateDocuments', allDocuments.data.documents);
        },
        async getUserDocuments({ commit }) {
            let allDocuments = await DocumentService.getUserDocuments();
            allDocuments.data.documents.forEach(document => {
                document.logo_source = getLogoSourceForFileType(document.filetype);
            })
            commit('populateDocuments', allDocuments.data.documents);
        },
        // Update your getPersonalDocuments action
        async getPersonalDocuments({ commit }, params = {}) {
            try {
                // Use params object or default to empty object for first page with default settings
                const response = await DocumentService.getPersonalDocuments(params);

                // Map file type icons to documents
                response.data.documents.forEach(document => {
                    document.logo_source = getLogoSourceForFileType(document.filetype);
                });

                // Pass both documents and pagination data
                commit('populateDocuments', response.data.documents);
                commit('setTotalItems', response.data.pagination.total || response.data.documents.length);

                return response;
            } catch (error) {
                console.error('Error fetching personal documents:', error);
                commit('populateDocuments', []);
                commit('setTotalItems', 0);
                return Promise.reject(error);
            }
        },
        async getSubscriberDocuments({ commit }, params = {}) {
            try {
                const response = await DocumentService.getSubscriberDocuments(params);
                // Map file type icons to documents
                response.data.documents.forEach(document => {
                    document.logo_source = getLogoSourceForFileType(document.filetype);
                });
                commit('populateDocuments', response.data.documents || []);
                return response;
            } catch (error) {
                console.error("Error fetching subscriber documents:", error);
                throw error;
            }
        },
        async getGroupDocuments({ commit }, uri) {
            let allDocuments = await DocumentService.getGroupDocuments(uri);
            allDocuments.data.documents.forEach(document => {
                document.logo_source = getLogoSourceForFileType(document.filetype);
            })
            commit('populateDocuments', allDocuments.data.documents);
        },
        async download({ commit }, file_id) {
            await DocumentService.download(file_id);
            commit('populateDocumentDetails', 'download')
        },
        async readDocument({ commit }, fileId) {
            let documentText = await DocumentService.readDocument(fileId)
            commit('populateDocumentText', documentText.data)
        },
        clearDocumentText({ commit }) {
            commit('populateDocumentText', null)
        },
        async delete({ commit }, file_id) {
            await DocumentService.delete(file_id)
            commit('populateDocumentDetails', 'delete')
        },
        async deleteGroupDocument({ commit }, file_id) {
            await DocumentService.deleteGroupDocument(file_id)
            commit('populateDocumentDetails', 'delete')
        },
        async select({ commit }, document_name) {
            commit('populateDocumentName', document_name)
        },
        async setErrorContent({ commit }, content) {
            commit('populateDocumentDetails', content)
        },
        async deleteSubscriberDocument({ dispatch }, documentId) {
            try {
                await DocumentService.deleteSubscriberDocument(documentId);
                dispatch('getUserDocuments'); // Reload documents after deletion
            } catch (error) {
                console.error('Error deleting document:', error);
            }
        }
    },
    mutations: {
        populateDocuments(state, documents) {
            state.documents = documents;
        },
        populateDocumentText(state, documentText) {
            state.documentText = documentText
        },
        populateDocumentDetails(state, document) {
            state.document = document
        },
        populateDocumentName(state, document_name) {
            state.document_name = document_name
        },
        setTotalItems(state, total) {
            state.totalItems = total;
        }
    },
}