import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Public/HomeView.vue";
import Login from "../views/Public/LoginView.vue";
import About from "../views/Public/AboutView.vue";
import Register from "../views/Public/RegisterView.vue";
import axios from 'axios';
import authHeader from '../services/auth-header';
import store from '@/store/index.js';
import NewPostView from '@/components/Blog/NewPostView.vue';

const AUTH_API_URL = process.env.VUE_APP_API_URL + '/auth';
const ConsultancyRequestForm = () => import("../views/Public/ConsultancyRequestForm.vue")
const DocumentManagementInfo = () => import("../views/Public/DocumentManagementProductInfo.vue")
const GroupDocumentManagementInfo = () => import("../views/Public/GroupDocumentManagementInfo.vue")
const WebDevelopmentRequestForm = () => import("../views/Public/WebDevelopmentRequestForm.vue")

// lazy-loaded
const Profile = () => import("../views/Auth/ProfileView.vue")
const AdminDashboard = () => import("../views/Admin/AdminDashboardView.vue")
const AdminRegisterUser = () => import("../views/Admin/AdminRegisterUserView.vue")
const PersonalDocuments = () => import("../components/Documents/PersonalDocuments.vue")
const GroupDocuments = () => import("../components/Groups/GroupDocumentsView.vue")
const AdminRegisterGroup = () => import("../views/Admin/AdminAddGroupView.vue")
const MobileMenu = () => import("../components/Navbar/MobileMenuView.vue")
const Groups = () => import("../views/Admin/GroupsView.vue")
const Verify = () => import("../views/Auth/VerifyView.vue")
const ProductSelect = () => import("../components/Products/ProductSelectView.vue")
const Enquiry = () => import("../views/Public/EnquiryView.vue")
const ManageGroupView = () => import("../views/Admin/ManageGroupView.vue")
const GroupAddUser = () => import("../views/Admin/GroupAddUserView.vue")
const ManageSubscriptions = () => import("../views/SuperAdmin/ManageSubscriptionsView.vue")
const AddSubscription = () => import("../components/Subscriptions/SubscriptionAddForm.vue")
const Payment = () => import("../views/Public/PaymentView.vue")
const ThankYou = () => import("../views/Public/ThankYouView.vue")
const Sorry = () => import("../views/Public/SorryView.vue")
const UserGroupManagement = () => import("../views/Auth/UserGroupManagement.vue")
const DocumentManagement = () => import("../views/Auth/DocumentManagement.vue")


const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/mobile-menu',
    component: MobileMenu
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/consultancy-request",
    name: "consultancy-request",
    component: ConsultancyRequestForm,
  },
  {
    path: "/document-management-info",
    name: "document-management-info",
    component: DocumentManagementInfo,
  },
  {
    path: "/group-document-management-info",
    name: "group-document-management-info",
    component: GroupDocumentManagementInfo,
  },
  {
    path: "/web-development-request",
    name: "web-development-request",
    component: WebDevelopmentRequestForm,
  },
  {
    path: '/blog/new',
    name: 'NewPost',
    component: NewPostView
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    // lazy-loaded
    component: ManageSubscriptions,
  },
  {
    path: "/add-subscription",
    name: "add subscription",
    // lazy-loaded
    component: AddSubscription,
  },
  {
    path: "/groups",
    name: "groups",
    // lazy-loaded
    component: Groups,
  },
  {
    path: "/development-blog",
    name: "development-blog",
    component: () => import("../components/Blog/BlogView.vue")
  },
  {
    path: "/admin-dashboard",
    name: "admin-dashboard",
    // lazy-loaded
    component: AdminDashboard,
  },
  {
    path: "/add-member",
    name: "add-member",
    // lazy-loaded
    component: AdminRegisterUser,
  },
  {
    path: "/add-group",
    name: "add-group",
    // lazy-loaded
    component: AdminRegisterGroup,
  },
  {
    path: "/Group Management",
    // lazy-loaded
    component: UserGroupManagement,
  },
  {
    path: "/Group%20Management",
    // lazy-loaded
    component: UserGroupManagement,
  },
  {
    path: '/document-versions/:id',
    name: 'DocumentVersions',
    component: () => import('@/components/Documents/DocumentVersionInfo.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/document/:id',
    name: 'DocumentEditor',
    component: () => import('@/components/Documents/DocumentEditor.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/Document-Management",
    alias: ["/document-management", "/Document Management", "/Document%20Management"],
    component: DocumentManagement,
    name: "document-management"
  },
  {
    path: '/subscriber-add-group',
    name: 'SubscriberAddGroup',
    component: () => import('@/views/Subscriber/SubscriberAddGroupView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: "/Document Management",
    component: DocumentManagement
  },
  {
    path: "/personal-documents",
    component: PersonalDocuments,
  },
  {
    path: "/group-documents/:uri?",
    component: GroupDocuments,
    props: true
  },
  {
    path: "/verify/:verification_code?",
    component: Verify,
    props: true
  },
  {
    path: "/group/:uri?",
    name: 'group',
    component: ManageGroupView,
    props: true
  },
  {
    path: "/add-group-member/:uri?",
    name: 'group member',
    component: GroupAddUser,
    props: true
  },
  {
    path: "/products",
    component: ProductSelect,
    props: route => ({
      initialEmail: route.query.email || null,
      initialProducts: route.query.products ?
        (Array.isArray(route.query.products) ? route.query.products : [route.query.products])
        : null
    })
  },
  {
    path: "/enquiry",
    component: Enquiry,
  },
  {
    path: "/payment",
    component: Payment,
    props: true
  },
  {
    path: "/thank-you/:session_id?",
    component: ThankYou,
    props: true
  },
  {
    path: "/sorry",
    component: Sorry
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    '/', 
    '/home', 
    '/login', 
    '/register', 
    '/about', 
    '/mobile-menu', 
    '/enquiry', 
    '/products',
    '/consultancy-request',
    '/document-management-info',
    '/group-document-management-info',
    '/web-development-request'];
  const authRequired = !publicPages.includes(to.path) && !to.path.startsWith('/verify') && !to.path.startsWith('/thank-you');
  try {
    if (authRequired) {
      let resp = await axios.get(AUTH_API_URL, { headers: authHeader() })
      if (resp.status == 200) {
        if (resp.data.loggedin === 'false') {
          store.commit('auth/logout')
          next('/');
        }
      }
    }
    next();
  }
  catch (err) {
    store.dispatch('auth/logout')
    next('/home');
  }
});

export default router;
