import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/document';
const USERS_API_URL = process.env.VUE_APP_API_URL + '/users';
const ORG_API_URL = process.env.VUE_APP_API_URL + '/group-documents';
const SUBSCRIBERS_URL = process.env.VUE_APP_API_URL + '/subscribers'

class documentService {
  // Add these methods to document.service.js

  initChunkedUpload(metadata) {
    const requiredMetadata = {
      filename: metadata.filename,
      totalSize: metadata.totalSize,
      totalChunks: metadata.totalChunks
    };

    // Include optional fields if present
    if (metadata.fileType) requiredMetadata.fileType = metadata.fileType;
    if (metadata.uploadType) requiredMetadata.uploadType = metadata.uploadType;

    return axios.post(SUBSCRIBERS_URL + "/init-chunked-upload", requiredMetadata, {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json'  // Explicitly set content type
      }
    });
  }

  // Upload a single chunk
  uploadChunk(formData) {
    return axios.post(SUBSCRIBERS_URL + "/upload-chunk", formData, {
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  finalizeChunkedUpload(metadata) {
    const finalizeData = {
      uploadId: metadata.uploadId,
      filename: metadata.filename,
      totalSize: metadata.totalSize
    };

    // Include optional fields if present
    if (metadata.fileType) finalizeData.fileType = metadata.fileType;
    if (metadata.uploadType) finalizeData.uploadType = metadata.uploadType;

    return axios.post(SUBSCRIBERS_URL + "/finalize-chunked-upload", finalizeData, {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json'  // Explicitly set content type
      }
    });
  }
  async getAllDocuments() {
    return await axios.get(API_URL, { headers: authHeader() });
  }
  getDocumentInfo(documentId) {
    return axios.get(`${API_URL}/info/${documentId}`, { headers: authHeader() });
  }
  async getUserDocuments() {
    return await axios.get(`${USERS_API_URL}/user/documents`, { headers: authHeader() })
  }
  async getGroupDocuments(uri) {
    return await axios.get(ORG_API_URL + '/' + uri, { headers: authHeader() })
  }
  async upload(files) {
    var formData = new FormData();
    formData.append('file', files);
    const response = await axios.post(API_URL, formData, { headers: authHeader() });
    return response.data;
  }
  async uploadPersonalDocument(files) {
    var formData = new FormData();
    formData.append('file', files);
    const response = await axios.post(API_URL + '/personal-documents', formData, { headers: authHeader() });
    return response.data;
  }
  getPersonalDocuments(params = {}) {
    const queryParams = new URLSearchParams();

    // Add parameters to query string
    if (params.page) queryParams.append('page', params.page);
    if (params.limit) queryParams.append('limit', params.limit);
    if (params.sort) queryParams.append('sort', params.sort);
    if (params.order) queryParams.append('order', params.order);
    if (params.search) queryParams.append('search', params.search);

    const queryString = queryParams.toString();
    const url = `${API_URL}/personal-documents${queryString ? '?' + queryString : ''}`;

    return axios.get(url, { headers: authHeader() });
  }
  async uploadSubscriberDocument(file, onProgressCallback = null) {
    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunks
    const CHUNKING_THRESHOLD = 10 * 1024 * 1024; // 10MB

    try {
      // Use chunked upload for files larger than threshold
      if (file.size > CHUNKING_THRESHOLD) {

        // Step 1: Initialize chunked upload
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

        // Create a clean metadata object with exact keys the server expects
        const metadata = {
          filename: file.name,
          totalSize: file.size,
          totalChunks: totalChunks,
          fileType: file.type,
          uploadType: 'subscriber-document'
        };

        // Report initial progress
        if (onProgressCallback) {
          onProgressCallback(0, { currentChunk: 0, totalChunks });
        }

        const initResponse = await this.initChunkedUpload(metadata);
        const uploadId = initResponse.data.upload_id;

        // Step 2: Upload each chunk
        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
          // Calculate the start and end position for the current chunk
          const start = chunkIndex * CHUNK_SIZE;
          const end = Math.min(file.size, start + CHUNK_SIZE);
          const chunk = file.slice(start, end);

          // Create form data for this chunk
          const chunkFormData = new FormData();
          chunkFormData.append('file', new Blob([chunk], { type: file.type }), file.name);
          chunkFormData.append('uploadId', uploadId);
          chunkFormData.append('chunkIndex', chunkIndex);
          chunkFormData.append('total_chunks', totalChunks);
          // Upload the chunk
          await this.uploadChunk(chunkFormData, chunkIndex, uploadId);

          // Report progress with detailed chunk info
          if (onProgressCallback) {
            const progress = Math.round(((chunkIndex + 1) / totalChunks) * 100);
            onProgressCallback(progress, {
              currentChunk: chunkIndex + 1,
              totalChunks: totalChunks
            });
          }
        }

        // Step 3: Finalize the upload
        const finalizeResponse = await this.finalizeChunkedUpload({
          uploadId,
          filename: file.name,
          totalSize: file.size,
          fileType: file.type,
          uploadType: 'subscriber-document'
        });

        // Report 100% complete
        if (onProgressCallback) {
          onProgressCallback(100);
        }

        return finalizeResponse.data;
      } else {
        // For smaller files, use direct upload with progress tracking
        if (onProgressCallback) {
          // Initial progress report
          onProgressCallback(0);
        }

        const formData = new FormData();
        formData.append('file', file);

        // Use axios with progress event
        const response = await axios.post(API_URL + '/subscriber-documents', formData, {
          headers: {
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (onProgressCallback) {
              onProgressCallback(percentCompleted);
            }
          }
        });
        return response.data;
      }
    } catch (error) {
      console.error('Upload error:', error);
      throw error;
    }
  }

  getSubscriberDocuments(params = {}) {
    // Default parameters for pagination
    const queryParams = {
      page: params.page || 1,
      limit: params.limit || 10,
      search: params.search || '',
      sort_by: params.sortBy || 'created_at',
      sort_dir: params.sortDir || 'desc'
    };

    // Convert params object to URL query string
    const queryString = Object.keys(queryParams)
      .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
      .join('&');

    return axios.get(`${API_URL}/subscriber-documents?${queryString}`, { headers: authHeader() });
  }
  async updateSubscriberDocumentText(fileId, text) {
    const response = await axios.put(`${API_URL}/save-document-text`, { fileId, text }, {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  }
  async uploadGroupDocument(files, uri) {
    var formData = new FormData();
    formData.append('file', files);
    const response = await axios.post(ORG_API_URL + '/' + uri, formData, { headers: authHeader() });
    return response.data.text();
  }
  async deleteGroupDocument(file) {
    let file_id = file.document_id
    await axios.delete(ORG_API_URL + '/delete/' + file_id, { headers: authHeader() });
  }
  async deleteSubscriberDocument(document) {
    const response = await axios.delete(API_URL + '/remove-subscriber-document/' + document.document_id, { headers: authHeader() });
    return response.data;
  }
  async readDocument(fileId) {
    return await axios.get(API_URL + '/read/' + fileId, { headers: authHeader() });
  }
  async download(file) {
    let file_id = file.document_id
    let response = await axios({
      url: API_URL + '/download/' + file_id,
      headers: authHeader(),
      method: 'GET',
      responseType: 'blob', // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.filename);
    document.body.appendChild(link);
    link.click();
  }
  async delete(file) {
    let file_id = file.document_id
    await axios.delete(API_URL + '/delete/' + file_id, { headers: authHeader() });
  }
}
export default new documentService();