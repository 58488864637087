<template>
    <thead>
        <tr>
            <th v-for="column in columns" :key="column.key"
                @click="column.sortable !== false ? handleSort(column.key) : null" :class="[
                    'brox-table-header',
                    {
                        'sortable': column.sortable !== false,
                        'sorted': sortField === column.key
                    }
                ]" :style="column.width ? { width: column.width } : {}"
                :title="column.sortable !== false ? 'Click to sort' : ''">
                <div class="header-content">
                    <span>{{ column.label }}</span>
                    <span v-if="column.sortable !== false && sortField === column.key" class="sort-icon">
                        <font-awesome-icon :icon="sortDir === 'asc' ? 'sort-up' : 'sort-down'" />
                    </span>
                </div>
            </th>
        </tr>
    </thead>
</template>

<script>
export default {
    name: 'BroxTableHeader',
    props: {
        columns: {
            type: Array,
            required: true
        },
        sortField: {
            type: String,
            default: null
        },
        sortDir: {
            type: String,
            default: 'asc'
        }
    },
    methods: {
        handleSort(field) {
            this.$emit('sort', field);
        }
    }
};
</script>

<style scoped>
thead {
    background-color: #f8f9fa;
}

.brox-table-header {
    text-align: left;
    padding: 0.5rem 0.75rem;
    /* Reduced padding */
    border-bottom: 2px solid #dee2e6;
    font-weight: 600;
    position: relative;
    height: 2.5rem;
    /* Fixed height for header */
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sortable {
    cursor: pointer;
}

.sortable:hover {
    background-color: #f1f3f5;
}

.sort-icon {
    margin-left: 0.5rem;
    color: #4F5D75;
}

th.sorted {
    background-color: rgba(79, 93, 117, 0.05);
}
</style>