import { Store } from 'vuex'
import { createStore } from 'vuex-extensions'
import { auth } from "./auth.module";
import { user } from "./user.module"
import { role } from './role.module';
import { documents } from './documents.module';
import { resizer } from "./resize.module";
import { groups} from './groups.module'
import { subscriptions } from "./subscriptions.module";
import { products } from './product.module'
import { admin } from "./admin.module"

const store = createStore(Store,{
  modules: {
    auth,
    user,
    role,
    documents,
    resizer,
    groups,
    subscriptions,
    products,
    admin
  },
});
export default store;
