<template>
    <div class="new-post-container">
        <h1>{{ isEditing ? 'Edit Post' : 'Create New Post' }}</h1>

        <div class="form-container">
            <!-- Title field -->
            <div class="form-group">
                <label for="post-title">Title *</label>
                <input id="post-title" v-model="post.title" type="text" class="form-control"
                    placeholder="Enter post title" required />
            </div>

            <!-- Author field -->
            <div class="form-group">
                <label for="post-author">Author *</label>
                <input id="post-author" v-model="post.author" type="text" class="form-control"
                    placeholder="Enter author name" required />
            </div>

            <!-- Excerpt field -->
            <div class="form-group">
                <label for="post-excerpt">Excerpt *</label>
                <textarea id="post-excerpt" v-model="post.excerpt" class="form-control"
                    placeholder="Brief summary of your post" rows="3" required></textarea>
            </div>

            <!-- Tags field -->
            <div class="form-group">
                <label>Tags</label>
                <div class="tags-input-container">
                    <div class="tags-display">
                        <span v-for="(tag, index) in post.tags" :key="index" class="tag">
                            {{ tag }}
                            <span class="remove-tag" @click="removeTag(index)">&times;</span>
                        </span>
                    </div>
                    <div class="tag-input-wrapper">
                        <input v-model="tagInput" type="text" class="tag-input" placeholder="Add a tag"
                            @keyup.enter="addTag" />
                        <button type="button" @click="addTag" class="add-tag-btn" :disabled="!tagInput.trim()">
                            Add
                        </button>
                    </div>
                </div>
            </div>

            <!-- Content field (CKEditor) -->
            <div class="form-group">
                <label for="post-content">Content *</label>
                <ckeditor v-model="content" :editor="editor" :config="editorConfig" />
            </div>

            <!-- Action buttons -->
            <div class="action-buttons">
                <button type="button" class="btn btn-preview" @click="previewPost">
                    Preview
                </button>
                <button type="button" class="btn btn-save" @click="saveAsDraft" :disabled="!isFormValid || isSaving">
                    Save as Draft
                </button>
                <button type="button" class="btn btn-publish" @click="publishPost" :disabled="!isFormValid || isSaving">
                    {{ isEditing ? 'Update' : 'Publish' }}
                </button>
            </div>
        </div>

        <!-- Preview modal -->
        <div v-if="showPreview" class="preview-modal">
            <div class="preview-content">
                <div class="preview-header">
                    <h2>Preview</h2>
                    <button @click="closePreview" class="close-preview">&times;</button>
                </div>
                <div class="post-preview">
                    <h1 class="post-title">{{ post.title }}</h1>
                    <div class="post-meta">
                        <span class="post-author">By {{ post.author }}</span>
                        <span class="post-date">{{ formatDate(new Date()) }}</span>
                    </div>
                    <div class="post-tags">
                        <span v-for="(tag, index) in post.tags" :key="index" class="tag">
                            {{ tag }}
                        </span>
                    </div>
                    <div class="post-content" v-html="post.content"></div>
                </div>
            </div>
        </div>

        <!-- Loading overlay when saving -->
        <div v-if="isSaving" class="saving-overlay">
            <div class="spinner"></div>
            <p>{{ savingMessage }}</p>
        </div>
    </div>
</template>

<script>
// Import CKEditor components as you have in DocumentContent.vue
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// If you're using a custom build of CKEditor, adjust the import accordingly

export default {
    name: 'NewPostView',
    components: {
        ckeditor: Ckeditor,
    },
    props: {
        // Pass an existing post if editing
        existingPost: {
            type: Object,
            default: null
        },
        blogText: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            content:this.blogText,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'
                ],
                height: 400
            },
            post: {
                id: null,
                title: '',
                author: '',
                date: new Date(),
                tags: [],
                excerpt: '',
                content: ''
            },
            tagInput: '',
            showPreview: false,
            isSaving: false,
            savingMessage: 'Saving...'
        };
    },
    computed: {
        isEditing() {
            return !!this.existingPost;
        },
        isFormValid() {
            return this.post.title &&
                this.post.author &&
                this.post.excerpt &&
                this.post.content;
        }
    },
    methods: {
        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }).format(date);
        },
        addTag() {
            const tag = this.tagInput.trim();
            if (tag && !this.post.tags.includes(tag)) {
                this.post.tags.push(tag);
            }
            this.tagInput = '';
        },
        removeTag(index) {
            this.post.tags.splice(index, 1);
        },
        previewPost() {
            this.showPreview = true;
        },
        closePreview() {
            this.showPreview = false;
        },
        async saveAsDraft() {
            try {
                this.isSaving = true;
                this.savingMessage = 'Saving draft...';

                // Prepare post data
                const postData = {
                    ...this.post,
                    status: 'draft',
                    date: new Date()
                };

                // Call your API service to save the draft
                // await BlogService.savePost(postData);

                // Show success notification or redirect
                this.$emit('saved', postData);

            } catch (error) {
                console.error('Error saving draft:', error);
                // Show error notification
            } finally {
                this.isSaving = false;
            }
        },
        async publishPost() {
            if (!this.isFormValid) return;

            try {
                this.isSaving = true;
                this.savingMessage = this.isEditing ? 'Updating post...' : 'Publishing post...';

                // Prepare post data
                const postData = {
                    ...this.post,
                    status: 'published',
                    date: new Date()
                };

                // Call your API service to publish the post
                // await BlogService.publishPost(postData);

                // Show success notification or redirect
                this.$emit('published', postData);

            } catch (error) {
                console.error('Error publishing post:', error);
                // Show error notification
            } finally {
                this.isSaving = false;
            }
        }
    },
    created() {
        // If editing an existing post, populate the form
        if (this.existingPost) {
            this.post = { ...this.existingPost };
        }
    }
};
</script>

<style scoped>
.new-post-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 2rem 1rem;
    font-family: 'Roboto-Slab', serif;
}

h1 {
    margin-bottom: 2rem;
    color: #333;
}

.form-container {
    background-color: #fff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
}

.form-group {
    margin-bottom: 1.5rem;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
}

.form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    font-family: inherit;
}

textarea.form-control {
    resize: vertical;
}

/* Tags styling */
.tags-input-container {
    margin-top: 0.5rem;
}

.tags-display {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.tag {
    display: inline-flex;
    align-items: center;
    background-color: #f0f0f0;
    color: #333;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.9rem;
}

.remove-tag {
    margin-left: 0.5rem;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 0.8;
}

.tag-input-wrapper {
    display: flex;
}

.tag-input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    font-size: 0.9rem;
}

.add-tag-btn {
    padding: 0 1rem;
    background-color: #4F5D75;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.add-tag-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* CKEditor styling */
:deep(.ck-editor__editable) {
    min-height: 300px;
}

/* Action buttons */
.action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
}

.btn-preview {
    background-color: #f0f0f0;
    color: #333;
}

.btn-save {
    background-color: #6c757d;
    color: white;
}

.btn-publish {
    background-color: #4F5D75;
    color: white;
}

.btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

/* Preview modal */
.preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.preview-content {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 2rem;
}

.preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
}

.close-preview {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Post preview styling */
.post-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.post-meta {
    color: #666;
    margin-bottom: 1rem;
}

.post-author {
    margin-right: 1rem;
}

.post-tags {
    margin: 1rem 0;
}

/* Spinner and overlay */
.saving-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #4F5D75;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>