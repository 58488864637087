<template>
  <div class="register-view">
    <h4>Sign up now for a free 10GB of document storage</h4>

    <!-- Show email form when not submitted -->
    <Form v-if="!submitted" @submit="handleRegister" :validation-schema="schema">
      <div class="form-group-inline">
        <label for="email">Email</label>
        <Field name="email" type="email" class="form-control" />
        <ErrorMessage name="email" class="error-message" />
        <button type="submit" class="btn btn-primary" :disabled="loading">
          <span v-if="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Sign Up
        </button>
      </div>
    </Form>

    <!-- Show spinner and message after submission -->
    <div v-else class="submission-status">
      <!-- Only show spinner when loading -->
      <div v-if="loading" class="spinner-container">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <!-- Success icon when successful and not loading -->
      <div v-if="successful && !loading" class="success-icon-container">
        <font-awesome-icon icon="check-circle" size="3x" class="text-success" />
      </div>

      <div v-if="message" class="alert mt-3" :class="successful ? 'alert-success' : loading ? 'alert-loading': 'alert-danger'">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "RegisterView",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
    });

    return {
      schema,
      message: "",
      successful: false,
      loading: false,
      submitted: false, // New state to track form submission
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    async handleRegister(user) {
      this.message = "Registering Account";
      this.successful = false;
      this.loading = true;

      try {
        // Mark as submitted before API call
        this.submitted = true;

        await this.$store.dispatch("auth/register", user);
        this.message = "Registration almost complete, an email has been sent to verify.";
        this.successful = true;
      } catch (error) {
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.successful = false;

        // If there's an error, allow resubmission
        this.submitted = false;
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.register-view {
  max-width: 50rem;
  margin: 0 auto;
  padding: 1.25rem;
  border: 0.0625rem solid #ccc;
  border-radius: 0.3125rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-group-inline {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.error-message {
  color: red;
  font-size: 0.875rem;
}

.alert {
  margin-top: 1rem;
  padding: 0.75rem 1.25rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-loading {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.3125rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* New styles for submission status */
.submission-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.spinner-container {
  margin: 1rem 0;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2rem;
  margin-right: 0.5rem;
}

.text-primary {
  color: #007bff !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.mt-3 {
  margin-top: 1rem;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
</style>