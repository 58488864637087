import axios from 'axios';
import authHeader from './auth-header';

const ROLES_API_URL = process.env.VUE_APP_API_URL + '/roles';

class RoleService {
  getRoles() {
    return axios.get(ROLES_API_URL, { headers: authHeader() });
  }
  getUserRoles() {
    return axios.get(ROLES_API_URL + '/users', { headers: authHeader() });
  }
}
export default new RoleService();
