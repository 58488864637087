<template>
    <div  class="navbar-nav ml-auto">
        <li class="nav-item">
            <router-link to="/admin-dashboard" class="nav-link">
                <font-awesome-icon icon="sign-in-alt" class="admin-icon" />User Management
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/groups" class="nav-link">
                <font-awesome-icon icon="sign-in-alt" class="admin-icon" />Groups
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/subscriptions" class="nav-link">
                <font-awesome-icon icon="file-contract" class="admin-icon" />Subscriptions
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/development-blog" class="nav-link">
                <font-awesome-icon icon="file-contract" class="admin-icon" />Dev Blog
            </router-link>
        </li>
    </div>
</template>
  
<script>
export default {
    name: 'NavbarSuperAdminView',
};
</script>
  
<style>
.products {
    list-style-type: none;
    /* Remove bullets */
    font-family: "Roboto Slab";
    font-size: x-large;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

.products:hover {
    background-color: rgb(250, 250, 250, 0.95);
    box-shadow: 0.4rem 0.6rem 0.6rem #1816168c;
}
</style>