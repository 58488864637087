<template>
    <tbody :class="{ 'loading-overlay-active': loading }">
      <tr v-for="(item, index) in items" :key="itemKey(item, index)">
        <td 
          v-for="column in columns" 
          :key="column.key" 
          :class="cellClass(column)"
          :style="column.cellStyle ? column.cellStyle : {}"
        >
          <slot :name="`cell-${column.key}`" :item="item" :index="index" :column="column">
            {{ getCellValue(item, column) }}
          </slot>
        </td>
      </tr>
  
      <!-- Loading overlay -->
      <div v-if="loading" class="table-loading-overlay">
        <div class="loading-spinner-container">
          <font-awesome-icon icon="spinner" class="fa-spin loading-spinner" />
          <span>{{ loadingMessage }}</span>
        </div>
      </div>
    </tbody>
  </template>
  
  <script>
  export default {
    name: 'BroxTableBody',
    props: {
      items: {
        type: Array,
        required: true
      },
      columns: {
        type: Array,
        required: true
      },
      loading: {
        type: Boolean,
        default: false
      },
      loadingMessage: {
        type: String,
        default: 'Loading data...'
      },
      itemKey: {
        type: Function,
        default: (item, index) => {
          return item.id || item._id || index;
        }
      }
    },
    methods: {
      cellClass(column) {
        return [
          'brox-table-cell',
          column.align ? `align-${column.align}` : '',
          column.class || ''
        ].filter(Boolean);
      },
      getCellValue(item, column) {
        if (column.formatter) {
          return column.formatter(item[column.key], item, column);
        }
        
        if (column.path) {
          return this.getNestedValue(item, column.path);
        }
        
        return item[column.key];
      },
      getNestedValue(obj, path) {
        return path.split('.').reduce((o, p) => o && o[p], obj);
      }
    }
  };
  </script>
  
  <style scoped>
  /* Table body styles */
  tbody {
  position: relative;
}

tr:hover {
  background-color: rgba(79, 93, 117, 0.05);
}

.brox-table-cell {
  padding: 0.5rem 0.75rem; /* Reduced padding for more compact rows */
  border-bottom: 1px solid #dee2e6;
  transition: background-color 0.2s;
  line-height: 1.3; /* Compact line height */
  height: 2.5rem; /* Fixed height for rows */
}
  
  /* Alignment classes */
  .align-left {
    text-align: left;
  }
  
  .align-center {
    text-align: center;
  }
  
  .align-right {
    text-align: right;
  }
  
  /* Loading overlay */
  .loading-overlay-active {
    opacity: 0.6;
    pointer-events: none;
  }
  
  .table-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }
  
  .loading-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .loading-spinner {
    font-size: 2rem;
    color: #4F5D75;
    margin-bottom: 0.5rem;
  }
  </style>