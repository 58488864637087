import axios from 'axios';
import authHeader from './auth-header';

const groupS_API_URL = process.env.VUE_APP_API_URL + '/groups';

class GroupService {
  async delete(group){
    await axios.delete(groupS_API_URL + '/delete/' + group, { headers: authHeader() });
  }
  async removeUser(email){
    await axios.delete(groupS_API_URL + '/users/delete/' + email, { headers: authHeader() });
  }
  async getGroups() {
    return await axios.get(groupS_API_URL, { headers: authHeader() });
  }
  async getUserSubscriptionGroups(){
    return await axios.get(groupS_API_URL + '/group-subscription', { headers: authHeader() });   
  }
  async addGroupFromSubscribedUser(group){
    var formData = new FormData()
    formData.append('name', group.name)
    formData.append('uri', group.uri)
    formData.append('lead_user_email', group.lead_user_email)
    return await axios.post(groupS_API_URL + '/group-subscription', formData, { headers: authHeader() })
  }
  async getGroup(uri){
    return await axios.get(groupS_API_URL + '/' + uri, {headers: authHeader() });
  }
  async getGroupUsers(uri){
    return await axios.get(groupS_API_URL + '/' + uri + '/users', {headers: authHeader() });
  }
  async addGroup(group){
    var formData = new FormData()
    formData.append('name', group.name)
    formData.append('uri', group.uri)
    formData.append('lead_user_email', group.lead_user_email)
    return await axios.post(groupS_API_URL, formData, { headers: authHeader() })
  }
  async addUserToGroup(userDetails){

    var formData = new FormData()
    formData.append('email', userDetails.email)
    formData.append('uri', userDetails.uri)
    formData.append('role', userDetails.role)
    return await axios.post(groupS_API_URL + '/' + userDetails.uri + '/users', formData, { headers: authHeader() })
  }
}
export default new GroupService();
