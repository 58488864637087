<template>
  <div class="hpc-login-container col-md-12">
    <div class="hpc-card card card-container">
      <Form @submit="handleLogin" :validation-schema="schema">
        <div class="form-group">
          <label for="email">Email</label>
          <Field name="email" type="text" class="form-control" />
          <ErrorMessage name="email" class="error-feedback" />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <Field name="password" type="password" class="form-control" />
          <ErrorMessage name="password" class="error-feedback" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else>Login</span>
          </button>
        </div>
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
      <!-- <Router-link to="/register">Or click here to register a new account</Router-link> -->
    </div>
  </div>
</template>
<script>

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "LoginView",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("Email is required!"),
      password: yup.string().required("Password is required!"),
    });
    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.redirectUserBasedOnSubscription();
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          // After successful login, redirect based on subscription
          this.redirectUserBasedOnSubscription();
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },

    // New method to handle redirection logic
    redirectUserBasedOnSubscription() {
      const currentUser = this.$store.state.auth.user;

      if (currentUser && currentUser.subscriptions) {
        // Check if user has an active document management subscription
        const hasDocumentManagement = currentUser.subscriptions.some(
          subscription =>
            subscription.product === "Document Management"
        );

        if (hasDocumentManagement) {
          // User has active document management subscription
          this.$router.push("/document-management");
          return;
        }
      }

      // Default path if no document management subscription
      this.$router.push("/personal-documents");
    }
  },
};
</script>

<style scoped>
.hpc-login-container {
  display: flex;
}

.hpc-card {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  font-family: 'Roboto-Slab';
  margin: auto;
  width: 20rem;
}

.hpc-card:hover {
  cursor: pointer;
}
</style>
