<template>
    <div class="brox-table-container">
      <slot name="before-table"></slot>
      
      <!-- Search and controls -->
      <div class="brox-table-top-controls">
        <slot name="search">
          <brox-table-search 
            v-if="searchable" 
            v-model="searchQuery" 
            @search="handleSearch"
          />
        </slot>
        
        <slot name="controls">
          <brox-table-controls 
            v-if="showLimitSelector"
            :limit="limit"
            :limit-options="limitOptions" 
            @limit-change="handleLimitChange" 
          />
        </slot>
      </div>
      
      <!-- Table -->
      <table v-if="items.length > 0 || loading" class="brox-table">
        <brox-table-header 
          :columns="columns" 
          :sort-field="sortField"
          :sort-dir="sortDir"
          @sort="handleSort"
        />
        
        <brox-table-body 
          :items="items"
          :columns="columns"
          :loading="loading"
          :loading-message="loadingMessage"
          v-bind="$attrs"
        >
          <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>
        </brox-table-body>
      </table>
      
      <!-- Empty state -->
      <div v-if="items.length === 0 && !loading" class="brox-table-empty">
        <slot name="empty">
          <p>No items found{{ searchQuery ? ' matching "' + searchQuery + '"' : '' }}</p>
        </slot>
      </div>
      
      <!-- Pagination -->
      <brox-table-pagination 
        v-if="paginated && totalPages > 1" 
        :current-page="currentPage"
        :total-pages="totalPages"
        @page-change="handlePageChange"
      />
      
      <slot name="after-table"></slot>
    </div>
  </template>
  
  <script>
  import BroxTableSearch from './BroxTableSearch.vue';
  import BroxTableControls from './BroxTableControls.vue';
  import BroxTableHeader from './BroxTableHeader.vue';
  import BroxTableBody from './BroxTableBody.vue';
  import BroxTablePagination from './BroxTablePagination.vue';
  
  export default {
    name: 'BroxTable',
    components: {
      BroxTableSearch,
      BroxTableControls,
      BroxTableHeader,
      BroxTableBody,
      BroxTablePagination
    },
    props: {
      // Data
      items: {
        type: Array,
        required: true
      },
      columns: {
        type: Array,
        required: true
      },
      
      // Pagination
      paginated: {
        type: Boolean,
        default: true
      },
      totalItems: {
        type: Number,
        default: 0
      },
      
      // Features flags
      searchable: {
        type: Boolean,
        default: true
      },
      showLimitSelector: {
        type: Boolean,
        default: true
      },
      
      // State flags
      loading: {
        type: Boolean,
        default: false
      },
      loadingMessage: {
        type: String,
        default: 'Loading data...'
      }
    },
    data() {
      return {
        searchQuery: '',
        currentPage: 1,
        limit: 10,
        limitOptions: [5, 10, 25, 50, 100],
        sortField: null,
        sortDir: 'asc'
      };
    },
    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.limit) || 1;
      }
    },
    methods: {
      handleSearch(query) {
        this.searchQuery = query;
        this.currentPage = 1;
        this.$emit('params-change', this.getParams());
      },
      
      handleLimitChange(newLimit) {
        this.limit = newLimit;
        this.currentPage = 1;
        this.$emit('params-change', this.getParams());
      },
      
      handleSort(field) {
        if (this.sortField === field) {
          this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortField = field;
          this.sortDir = 'asc';
        }
        this.$emit('params-change', this.getParams());
      },
      
      handlePageChange(page) {
        this.currentPage = page;
        this.$emit('params-change', this.getParams());
      },
      
      getParams() {
        return {
          page: this.currentPage,
          limit: this.limit,
          search: this.searchQuery,
          sort: this.sortField,
          order: this.sortDir
        };
      },
      
      // Method to reset the table to its initial state
      reset() {
        this.currentPage = 1;
        this.searchQuery = '';
        this.sortField = null;
        this.sortDir = 'asc';
      }
    }
  };
  </script>

<style scoped>
.brox-table-container {
  width: 100%;
  font-family: inherit;
  margin-bottom: 1rem;
  max-width: 100%; /* Ensure container doesn't restrict width */
}

.brox-table-top-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem; /* Reduced from 1rem */
  flex-wrap: wrap;
  gap: 0.75rem; /* Reduced from 1rem */
}

.brox-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;
  min-height: 80px; /* Reduced from 100px */
  table-layout: fixed; /* Helps with column width control */
}

/* Make rows more compact */
:deep(.brox-table-cell) {
  padding: 0.5rem 0.75rem; /* Reduced from 0.75rem */
  border-bottom: 1px solid #dee2e6;
  transition: background-color 0.2s;
  line-height: 1.3; /* Tighter line height */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.brox-table-header) {
  padding: 0.5rem 0.75rem; /* Reduced from 0.75rem */
  font-size: 0.95rem; /* Slightly smaller header text */
}

.brox-table-empty {
  text-align: center;
  padding: 1.5rem; /* Reduced from 2rem */
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  color: #666;
  margin: 0.75rem 0; /* Reduced from 1rem */
}

/* Responsive styles */
@media (max-width: 768px) {
  .brox-table-top-controls {
    flex-direction: column;
    align-items: stretch;
  }
  
  .brox-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  :deep(.brox-table-search .search-container) {
    width: 100%;
  }
}

/* Additional styles for making table container wider */
@media (min-width: 769px) {
  .brox-table-container {
    width: 100%;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    padding: 0 0.5rem;
  }
}
</style>