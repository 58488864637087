import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/auth';
const SIGNUP_URL = process.env.VUE_APP_API_URL + '/signup';

class AuthService {
  async login(user) {
    const response = await axios
      .put(API_URL, {
        email: user.email,
        password: user.password
      });
    if (response.data.access_token) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
  }
  async refreshToken() {
    try {
      let user = await axios.get(API_URL, { headers: authHeader() })
      localStorage.setItem('user', JSON.stringify(user.data));
      return user.data
    }
    catch (error) {
      return false
    }
  }

  logout() {
    localStorage.removeItem('user');
  }
  async register(user) {
    // registers plain user account - free account
    return await axios.post(SIGNUP_URL, {
      email: user.email,
    });
  }
  async adminUpdateUser(user) {
    // updates plain user account - free account
    var formData = new FormData()
    formData.append('first_name', user.first_name)
    formData.append('last_name', user.last_name)
    formData.append('role', user.role)
    formData.append('email', user.email)
    formData.append('verified', user.verified)
    if (user.new_email) {
      formData.append('new_email', user.new_email)
    }
    return await axios.put(API_URL + '/admin', formData, { headers: authHeader() })
  }
  async adminRegister(user) {
    var formData = new FormData()
    formData.append('first_name', user.first_name)
    formData.append('last_name', user.last_name)
    formData.append('role', user.role)
    formData.append('email', user.email)
    formData.append('password', user.password)
    return await axios.post(API_URL + '/admin', formData, { headers: authHeader() })
  }
  // Add these methods to your AuthService class
  verifyEmailToken(token) {
    return axios.get(`${SIGNUP_URL}/verify-email/${token}`);
  }

  completeSignup(data) {
    return axios.post(`${SIGNUP_URL}/complete`, {
      token: data.token,
      password: data.password,
      first_name: data.first_name,
      last_name: data.last_name
    });
  }
  async verify(user) {
    // registers plain user account - free account
    return await axios.post(SIGNUP_URL + '/verify', {
      email: user.email,
      verification_code: user.verification_code,
      password: user.password,
    });
  }
  async delete(email) {
    const formData = new FormData();
    formData.append('email', email);
    
    await axios.delete(API_URL, { 
      headers: authHeader(),
      data: formData 
    });
  }
  setPassword(password, token) {
    return axios.post(API_URL + "/set-password", {
      password,
      token
    });
  }
}
export default new AuthService();
