import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faPlus,
  faMinus,
  faHome,
  faContactCard,
  faFileContract,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faPhone,
  faTrash,
  faFile,
  faCircleArrowDown,
  faSpinner,
  faBasketShopping,
  faCartShopping,
  faBars,
  faUserMinus,
  faCalendar,
  faDonate,
  faMoneyBillTransfer,
  faCashRegister,
  faBookOpenReader,
  faGlobe,
  faCheck,
  faCross,
  faDownload,
  faExclamation,
  faExclamationCircle,
  faSync,
  faCheckCircle,
  faUpload,
  faSearch,
  faSortUp,
  faChevronLeft,
  faChevronRight,
  faCloudUploadAlt,
  faTimes,
  faFileUpload
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faPlus,
  faMinus,
  faHome, 
  faContactCard,
  faFileContract,
  faUser, 
  faUserPlus, 
  faSignInAlt, 
  faSignOutAlt, 
  faPhone, 
  faTrash, 
  faFile, 
  faCircleArrowDown, 
  faSpinner, 
  faBasketShopping, 
  faCartShopping,
  faBars,
  faUserMinus,
  faCalendar,
  faDonate,
  faMoneyBillTransfer,
  faCashRegister,
  faBookOpenReader,
  faGlobe,
  faCheck,
  faCross,
  faDownload,
  faExclamation,
  faExclamationCircle,
  faSync,
  faCheckCircle,
  faUpload,
  faSearch,
  faSortUp,
  faChevronLeft,
  faChevronRight,
  faCloudUploadAlt,
  faTimes,
  faFileUpload
);
export { FontAwesomeIcon };
