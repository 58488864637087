<template>
    <div 
      class="brox-table-cell-content" 
      :class="{ 
        'clickable': clickable,
        'truncate': truncate
      }"
      @click="handleClick"
    >
      <slot>
        <template v-if="!noFormat && formatter">
          {{ formatter(value, item, column) }}
        </template>
        <template v-else>
          {{ displayValue }}
        </template>
      </slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BroxTableCell',
    props: {
      value: {
        type: [String, Number, Boolean, Object, Array],
        default: null
      },
      item: {
        type: Object,
        default: () => ({})
      },
      column: {
        type: Object,
        default: () => ({})
      },
      formatter: {
        type: Function,
        default: null
      },
      noFormat: {
        type: Boolean,
        default: false
      },
      clickable: {
        type: Boolean,
        default: false
      },
      truncate: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      displayValue() {
        if (this.value === undefined || this.value === null) {
          return '';
        }
        return this.value;
      }
    },
    methods: {
      handleClick() {
        if (this.clickable) {
          this.$emit('click', {
            value: this.value,
            item: this.item,
            column: this.column
          });
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .brox-table-cell-content {
    padding: 0.15rem 0;
  }
  
  .clickable {
    cursor: pointer;
    color: #1a4a85;
    text-decoration: underline;
  }
  
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  </style>