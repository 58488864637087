import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class AdminService {
  // User Management
  registerUser(data) {
    return axios.post(`${API_URL}/admin/auth`, data, { headers: authHeader() });
  }

  getAllUsers(page = 1, limit = 20, search = '') {
    return axios.get(`${API_URL}/admin/auth`, {
      headers: authHeader(),
      params: { page, limit, search }
    });
  }

  updateUser(data) {
    return axios.put(`${API_URL}/admin/auth`, data, { headers: authHeader() });
  }

  getUserById(userId) {
    return axios.get(`${API_URL}/admin/auth/${userId}`, { headers: authHeader() });
  }

  updateUserById(userId, data) {
    return axios.put(`${API_URL}/admin/auth/${userId}`, data, { headers: authHeader() });
  }

  // Group Management
  createGroup(data) {
    return axios.post(`${API_URL}/admin/groups`, data, { headers: authHeader() });
  }

  getAllGroups(page = 1, limit = 20, search = '') {
    return axios.get(`${API_URL}/admin/groups`, {
      headers: authHeader(),
      params: { page, limit, search }
    });
  }

  updateGroup(data) {
    return axios.put(`${API_URL}/admin/groups`, data, { headers: authHeader() });
  }

  deleteGroup(data) {
    return axios.delete(`${API_URL}/admin/groups`, {
      headers: authHeader(),
      data
    });
  }

  // Subscription Management
  createSubscription(data) {
    return axios.post(`${API_URL}/admin/subscriptions`, data, {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json'
      }
    });
  }

  getAllSubscriptions(page = 1, limit = 20, search = '') {
    return axios.get(`${API_URL}/admin/subscriptions`, {
      headers: authHeader(),
      params: { page, limit, search }
    });
  }

  updateSubscription(data) {
    return axios.put(`${API_URL}/admin/subscriptions`, data, { headers: authHeader() });
  }

  deleteSubscription(data) {
    return axios.delete(`${API_URL}/admin/subscriptions`, {
      headers: authHeader(),
      data
    });
  }

  // Document Management
  createDocument(data) {
    return axios.post(`${API_URL}/admin/documents`, data, { headers: authHeader() });
  }

  getAllDocuments(page = 1, limit = 20, search = '') {
    return axios.get(`${API_URL}/admin/documents`, {
      headers: authHeader(),
      params: { page, limit, search }
    });
  }

  updateDocument(data) {
    return axios.put(`${API_URL}/admin/documents`, data, { headers: authHeader() });
  }

  deleteDocument(data) {
    return axios.delete(`${API_URL}/admin/documents`, {
      headers: authHeader(),
      data
    });
  }
}

export default new AdminService();