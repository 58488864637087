import AdminService from '@/services/admin.service';

const initialState = {
  users: [],
  groups: [],
  subscriptions: [],
  documents: [],
  totalUsers: 0,
  totalGroups: 0,
  totalSubscriptions: 0,
  totalDocuments: 0,
};

export const admin = {
  namespaced: true,
  state: initialState,
  actions: {
    async registerUser({ commit }, data) {
      const response = await AdminService.registerUser(data);
      commit('addUser', response.data);
      return response.data;
    },
    async getAllUsers({ commit }, { page, limit, search }) {
      const response = await AdminService.getAllUsers(page, limit, search);
      commit('setUsers', response.data);
      return response.data;
    },
    async updateUser({ commit }, data) {
      const response = await AdminService.updateUser(data);
      commit('updateUser', response.data);
      return response.data;
    },
    async getUserById({ commit }, userId) {
      const response = await AdminService.getUserById(userId);
      commit('setUser', response.data);
      return response.data;
    },
    async updateUserById({ commit }, { userId, data }) {
      const response = await AdminService.updateUserById(userId, data);
      commit('updateUser', response.data);
      return response.data;
    },
    async createGroup({ commit }, data) {
      const response = await AdminService.createGroup(data);
      commit('addGroup', response.data);
      return response.data;
    },
    async getAllGroups({ commit }, { page, limit, search }) {
      const response = await AdminService.getAllGroups(page, limit, search);
      commit('setGroups', response.data);
      return response.data;
    },
    async updateGroup({ commit }, data) {
      const response = await AdminService.updateGroup(data);
      commit('updateGroup', response.data);
      return response.data;
    },
    async deleteGroup({ commit }, data) {
      const response = await AdminService.deleteGroup(data);
      commit('removeGroup', data.id);
      return response.data;
    },
    async createSubscription({ commit }, data) {
      const response = await AdminService.createSubscription(data);
      commit('addSubscription', response.data);
      return response.data;
    },
    async getAllSubscriptions({ commit }, { page, limit, search }) {
      const response = await AdminService.getAllSubscriptions(page, limit, search);
      commit('setSubscriptions', response.data);
      return response.data;
    },
    async updateSubscription({ commit }, data) {
      const response = await AdminService.updateSubscription(data);
      commit('updateSubscription', response.data);
      return response.data;
    },
    async deleteSubscription({ commit }, data) {
      const response = await AdminService.deleteSubscription(data);
      commit('removeSubscription', data.id);
      return response.data;
    },
    async createDocument({ commit }, data) {
      const response = await AdminService.createDocument(data);
      commit('addDocument', response.data);
      return response.data;
    },
    async getAllDocuments({ commit }, { page, limit, search }) {
      const response = await AdminService.getAllDocuments(page, limit, search);
      commit('setDocuments', response.data);
      return response.data;
    },
    async updateDocument({ commit }, data) {
      const response = await AdminService.updateDocument(data);
      commit('updateDocument', response.data);
      return response.data;
    },
    async deleteDocument({ commit }, data) {
      const response = await AdminService.deleteDocument(data);
      commit('removeDocument', data.id);
      return response.data;
    },
  },
  mutations: {
    addUser(state, user) {
      state.users.push(user);
    },
    setUsers(state, { users, total }) {
      state.users = users;
      state.totalUsers = total;
    },
    updateUser(state, updatedUser) {
      const index = state.users.findIndex(user => user.id === updatedUser.id);
      if (index !== -1) {
        state.users.splice(index, 1, updatedUser);
      }
    },
    setUser(state, user) {
      const index = state.users.findIndex(u => u.id === user.id);
      if (index !== -1) {
        state.users.splice(index, 1, user);
      } else {
        state.users.push(user);
      }
    },
    addGroup(state, group) {
      state.groups.push(group);
    },
    setGroups(state, { groups, total }) {
      state.groups = groups;
      state.totalGroups = total;
    },
    updateGroup(state, updatedGroup) {
      const index = state.groups.findIndex(org => org.id === updatedGroup.id);
      if (index !== -1) {
        state.groups.splice(index, 1, updatedGroup);
      }
    },
    removeGroup(state, groupId) {
      state.groups = state.groups.filter(org => org.id !== groupId);
    },
    addSubscription(state, subscription) {
      state.subscriptions.push(subscription);
    },
    setSubscriptions(state, { subscriptions, total }) {
      state.subscriptions = subscriptions;
      state.totalSubscriptions = total;
    },
    updateSubscription(state, updatedSubscription) {
      const index = state.subscriptions.findIndex(sub => sub.id === updatedSubscription.id);
      if (index !== -1) {
        state.subscriptions.splice(index, 1, updatedSubscription);
      }
    },
    removeSubscription(state, subscriptionId) {
      state.subscriptions = state.subscriptions.filter(sub => sub.id !== subscriptionId);
    },
    addDocument(state, document) {
      state.documents.push(document);
    },
    setDocuments(state, { documents, total }) {
      state.documents = documents;
      state.totalDocuments = total;
    },
    updateDocument(state, updatedDocument) {
      const index = state.documents.findIndex(doc => doc.id === updatedDocument.id);
      if (index !== -1) {
        state.documents.splice(index, 1, updatedDocument);
      }
    },
    removeDocument(state, documentId) {
      state.documents = state.documents.filter(doc => doc.id !== documentId);
    },
  },
};