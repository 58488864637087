import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome'
// In your main.js or a separate plugins/tables.js file
import BroxTable from '@/components/Table/BroxTable.vue';
import BroxTableSearch from '@/components/Table/BroxTableSearch.vue';
import BroxTableControls from '@/components/Table/BroxTableControls.vue';
import BroxTableHeader from '@/components/Table/BroxTableHeader.vue';
import BroxTableBody from '@/components/Table/BroxTableBody.vue';
import BroxTablePagination from '@/components/Table/BroxTablePagination.vue';
import BroxTableCell from '@/components/Table/BroxTableCell.vue';

// import { StripePlugin } from '@vue-stripe/vue-stripe';

// const options = {
//   pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
//   stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
//   apiVersion: process.env.API_VERSION,
//   locale: process.env.LOCALE,
// };

// Create the app instance
const app = createApp(App)

// Register components globally with the Vue 3 syntax
app.component('BroxTable', BroxTable)
app.component('BroxTableSearch', BroxTableSearch)
app.component('BroxTableControls', BroxTableControls)
app.component('BroxTableHeader', BroxTableHeader)
app.component('BroxTableBody', BroxTableBody)
app.component('BroxTablePagination', BroxTablePagination)
app.component('BroxTableCell', BroxTableCell)
app.component("font-awesome-icon", FontAwesomeIcon)

app
    // .use(StripePlugin, options)
    .use(store)
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
