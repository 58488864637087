<template>
    <div class="brox-table-pagination">
      <button 
        @click="$emit('page-change', currentPage - 1)" 
        :disabled="currentPage === 1" 
        class="page-button"
        title="Previous page"
        aria-label="Go to previous page"
      >
        <font-awesome-icon icon="chevron-left" />
      </button>
      
      <button 
        v-for="page in displayedPages" 
        :key="page" 
        @click="handlePageClick(page)"
        :disabled="page === '...'"
        :class="[
          'page-button', 
          page === currentPage ? 'current-page' : '',
          page === '...' ? 'ellipsis' : ''
        ]"
        :title="page !== '...' ? `Go to page ${page}` : ''"
        :aria-label="page !== '...' ? `Go to page ${page}` : 'More pages'"
        :aria-current="page === currentPage ? 'page' : false"
      >
        {{ page }}
      </button>
      
      <button 
        @click="$emit('page-change', currentPage + 1)" 
        :disabled="currentPage === totalPages" 
        class="page-button"
        title="Next page"
        aria-label="Go to next page"
      >
        <font-awesome-icon icon="chevron-right" />
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BroxTablePagination',
    props: {
      currentPage: {
        type: Number,
        required: true
      },
      totalPages: {
        type: Number,
        required: true
      },
      maxVisiblePages: {
        type: Number,
        default: 5
      }
    },
    computed: {
      displayedPages() {
        const pages = [];
        const maxDisplayedPages = this.maxVisiblePages;
  
        if (this.totalPages <= maxDisplayedPages) {
          // Show all pages if total is less than maxDisplayedPages
          for (let i = 1; i <= this.totalPages; i++) {
            pages.push(i);
          }
        } else {
          // Always show first page
          pages.push(1);
  
          // Calculate start and end pages to show around current page
          let startPage = Math.max(2, this.currentPage - 1);
          let endPage = Math.min(this.totalPages - 1, this.currentPage + 1);
  
          // Adjust if near start or end
          if (this.currentPage <= 3) {
            endPage = Math.min(4, this.totalPages - 1);
          } else if (this.currentPage >= this.totalPages - 2) {
            startPage = Math.max(2, this.totalPages - 3);
          }
  
          // Add ellipsis if needed
          if (startPage > 2) {
            pages.push('...');
          }
  
          // Add middle pages
          for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
          }
  
          // Add ellipsis if needed
          if (endPage < this.totalPages - 1) {
            pages.push('...');
          }
  
          // Always show last page
          if (this.totalPages > 1) {
            pages.push(this.totalPages);
          }
        }
  
        return pages;
      }
    },
    methods: {
      handlePageClick(page) {
        if (page !== '...' && page !== this.currentPage) {
          this.$emit('page-change', page);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .brox-table-pagination {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    align-items: center;
  }
  
  .page-button {
    padding: 0.4rem 0.7rem;
    margin: 0 0.2rem;
    border: 1px solid #dee2e6;
    background-color: #fff;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.9rem;
    min-width: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
  }
  
  .page-button:hover:not(:disabled):not(.current-page) {
    background-color: #f5f5f5;
    border-color: #c1c9d0;
  }
  
  .page-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .current-page {
    background-color: #4F5D75;
    color: white;
    border-color: #4F5D75;
    font-weight: bold;
  }
  
  .ellipsis {
    background-color: transparent;
    border: none;
    pointer-events: none;
  }
  </style>