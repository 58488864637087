import UserService from '../services/user.service';
import AuthService from '@/services/auth.service';

const initialState = {
  users: [],
  user: null,
  reset: false,
  userProfile: null,
  // Store the complete pagination object
  pagination: {
    page: 1,
    limit: 10,
    total: 0,
    pages: 1
  },
  // Keep these for backward compatibility
  totalUsers: 0,
  currentPage: 1,
  pageSize: 10,
  searchQuery: '',
  sortField: 'createdAt',
  sortOrder: 'desc'
}

export const user = {
  namespaced: true,
  state: initialState,
  actions: {
    async getUsers({ commit, state }, params = {}) {
      // Use provided params or fallback to state values
      const requestParams = {
        page: params.page || state.pagination.page,
        limit: params.limit || state.pagination.limit,
        search: params.search || state.searchQuery,
        sortField: params.sort || params.sortField || state.sortField,
        sortOrder: params.order || params.sortOrder || state.sortOrder
      };

      try {
        // Call API with the parameters
        const response = await UserService.getUsers(requestParams);
        const { users, pagination } = response.data;
        
        // Store both users and pagination data
        commit('populateUsers', users);
        commit('setPagination', pagination);
        
        // Also update individual fields for backward compatibility
        commit('setTotalUsers', pagination.total);
        commit('setCurrentPage', pagination.page);
        commit('setPageSize', pagination.limit);
        
        // Store search and sort parameters
        if (params.search !== undefined) commit('setSearchQuery', params.search);
        if (params.sort || params.sortField) commit('setSortField', params.sort || params.sortField);
        if (params.order || params.sortOrder) commit('setSortOrder', params.order || params.sortOrder);
        
        return response;
      } catch (error) {
        console.error("Error in getUsers action:", error);
        throw error;
      }
    },

    // Your other actions remain unchanged
    async getUser({ commit }, userEmail) {
      let userResponse = await UserService.getUser(userEmail);
      commit('populateUserProfile', userResponse.data.user);
    },
    
    async sendEmail({ commit }, userEmail) {
      await UserService.sendEmail(userEmail)
      commit('passwordReset')
    },
    
    async remove(user) {
      await AuthService.delete(user)
    },
    
    async updateProfile({ commit }, userArgs) {
      await UserService.updateProfile(userArgs);
      commit('populateUserProfile', userArgs);
    },
    
    // Reset pagination and search when needed
    resetFilters({ commit, dispatch }) {
      commit('setPagination', {
        page: 1,
        limit: 10,
        total: 0,
        pages: 1
      });
      commit('setSearchQuery', '');
      commit('setSortField', 'createdAt');
      commit('setSortOrder', 'desc');

      // Reload with reset parameters
      return dispatch('getUsers');
    }
  },
  mutations: {
    populateUsers(state, users) {
      state.users = users;
    },
    
    passwordReset(state) {
      state.reset = true
    },
    
    populateUserProfile(state, profile) {
      state.userProfile = profile;
    },
    
    // New mutation to set the entire pagination object
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    
    // Keep individual mutations for backward compatibility
    setTotalUsers(state, total) {
      state.totalUsers = total;
    },

    setCurrentPage(state, page) {
      state.currentPage = page;
      // Keep pagination object in sync
      state.pagination.page = page;
    },

    setPageSize(state, size) {
      state.pageSize = size;
      // Keep pagination object in sync
      state.pagination.limit = size;
    },

    setSearchQuery(state, query) {
      state.searchQuery = query;
    },

    setSortField(state, field) {
      state.sortField = field;
    },

    setSortOrder(state, order) {
      state.sortOrder = order;
    },
  },
  getters: {
    totalPages: state => {
      return state.pagination.pages || Math.ceil(state.pagination.total / state.pagination.limit);
    },
    
    // Add more getters for convenience
    currentPage: state => state.pagination.page,
    pageSize: state => state.pagination.limit,
    totalItems: state => state.pagination.total
  }
}