<template>
    <div class="brox-table-controls">
      <div class="limit-container">
        <label :for="limitId" class="limit-label">Show:</label>
        <select 
          :id="limitId"
          :value="limit" 
          @change="$emit('limit-change', parseInt($event.target.value))"
          class="limit-select"
        >
          <option 
            v-for="option in limitOptions" 
            :key="option" 
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <span class="limit-text">per page</span>
      </div>
      
      <slot name="additional-controls"></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BroxTableControls',
    props: {
      limit: {
        type: Number,
        required: true
      },
      limitOptions: {
        type: Array,
        default: () => [5, 10, 25, 50, 100]
      }
    },
    data() {
      return {
        limitId: `limit-select-${Math.random().toString(36).substring(2, 10)}`
      };
    }
  };
  </script>
  
  <style scoped>
  .brox-table-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .limit-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.95rem;
  }
  
  .limit-label {
    color: #555;
  }
  
  .limit-select {
    padding: 0.4rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    font-family: inherit;
    font-size: 0.95rem;
    transition: border-color 0.2s;
    cursor: pointer;
  }
  
  .limit-select:focus {
    outline: none;
    border-color: #4F5D75;
    box-shadow: 0 0 0 2px rgba(79, 93, 117, 0.2);
  }
  
  .limit-text {
    color: #555;
  }
  </style>