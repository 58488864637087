<template>
    <div class="brox-table-search">
      <div class="search-container">
        <input 
          type="text" 
          :value="modelValue"
          @input="handleInput"
          placeholder="Search..." 
          class="search-input"
          :id="searchId"
        />
        <font-awesome-icon icon="search" class="search-icon" />
        <button 
          v-if="modelValue" 
          @click="clearSearch" 
          class="clear-search-button" 
          title="Clear search"
          type="button"
        >
          <font-awesome-icon icon="times" />
          <span class="sr-only">Clear search</span>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import { debounce } from 'lodash';
  
  export default {
    name: 'BroxTableSearch',
    props: {
      modelValue: {
        type: String,
        default: ''
      },
      debounceTime: {
        type: Number,
        default: 300
      },
      placeholder: {
        type: String,
        default: 'Search...'
      }
    },
    data() {
      return {
        searchId: `search-${Math.random().toString(36).substring(2, 10)}`
      };
    },
    created() {
      // Create debounced search function
      this.debouncedEmit = debounce(value => {
        this.$emit('search', value);
      }, this.debounceTime);
    },
    methods: {
      handleInput(event) {
        const value = event.target.value;
        this.$emit('update:modelValue', value);
        this.debouncedEmit(value);
      },
      clearSearch() {
        this.$emit('update:modelValue', '');
        this.$emit('search', '');
      }
    },
    beforeUnmount() {
      if (this.debouncedEmit && typeof this.debouncedEmit.cancel === 'function') {
        this.debouncedEmit.cancel();
      }
    }
  };
  </script>
  
  <style scoped>
  .brox-table-search {
    margin-bottom: 1rem;
  }
  
  .search-container {
    position: relative;
    width: 300px;
  }
  
  .search-input {
    width: 100%;
    padding: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: inherit;
    font-size: 0.95rem;
    transition: border-color 0.2s;
  }
  
  .search-input:focus {
    outline: none;
    border-color: #4F5D75;
    box-shadow: 0 0 0 2px rgba(79, 93, 117, 0.2);
  }
  
  .search-icon {
    position: absolute;
    left: 0.6rem;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }
  
  .clear-search-button {
    position: absolute;
    right: 0.6rem;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: #666;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
  }
  
  .clear-search-button:hover {
    color: #333;
    background-color: #f0f0f0;
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  </style>